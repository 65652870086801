import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
import data from "./data.yaml";
export const YamlTable = ({
  data
}) => {
  console.log(data);
  const fields = [['Name', g => g.name.en], //[ 'Jp Name', g=>g.name.jp ],
  //[ 'Translated Name', g=>g.name['jp-en'] ],
  ['NA/PAL/KR', g => g.released.en ? '✅' : '❌'], ['JP/KR', g => g.released.jp ? '✅' : '❌']];
  return <table style={{
    maxWidth: `960px`,
    margin: `1.45rem`
  }}>
    <thead><tr>{fields.map(([key]) => <th key={key}>{key}</th>)}</tr></thead>
    <tbody>
      {data.map((game, index) => {
        return <tr key={`content_item_${index}`}>{fields.map(([key, fn]) => <td key={key}>{fn(game)}</td>)}</tr>;
      })}
    </tbody>
  </table>;
};
export const _frontmatter = {};
const layoutProps = {
  YamlTable,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "nes"
    }}>{`NES`}</h1>
    <YamlTable data={data} mdxType="YamlTable" />
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`jp name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`translated name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`en`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`jp`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Balloon Fight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h1 {...{
      "id": "snes"
    }}>{`SNES`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`//TODO
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      